import React from "react"
import { Helmet } from "react-helmet"
import "typeface-montserrat-alternates"
import Header from "../components/header"
import Footer from "../components/footer"
import "./layout.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false
export default ({ children }) => (
    <div>
        <Header />

        {children}

        <Footer />
        <Helmet>
            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap"
                rel="stylesheet"
            />
        </Helmet>
    </div>
)